import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pb-0" }
const _hoisted_3 = { class: "d-flex flex-column flex-wrap flex-sm-nowrap break-all p-0" }
const _hoisted_4 = { class: "text-gray-400" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder" }
const _hoisted_7 = { class: "nav-item mt-2" }
const _hoisted_8 = { class: "nav-item mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_window_resize = _resolveComponent("window-resize")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_window_resize, null, {
            sm: _withCtx(() => [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.DraftOrderOverview.config_name), 1),
              (_ctx.DraftOrderOverview.user_account_name)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.DraftOrderOverview.user_account_name), 1))
                : _createCommentVNode("", true)
            ]),
            lg: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.DraftOrderOverview.config_name + " / ") + " " + _toDisplayString(_ctx.DraftOrderOverview.user_account_name
                  ? _ctx.DraftOrderOverview.user_account_name
                  : ""), 1)
            ]),
            _: 1
          })
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("ul", _hoisted_6, [
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: _ctx.getDraftOrderRouteUrl(_ctx.$route) + _ctx.$route.params.id + '/overview',
              replace: "",
              class: "nav-link text-active-primary ms-0 me-10 py-5",
              "active-class": "active"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("salesOrder.overview")), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_8, [
            (_ctx.isChangeData && _ctx.isOverview)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "nav-link text-active-primary ms-0 me-10 py-5",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goSalesProfilling && _ctx.goSalesProfilling(...args)))
                }, _toDisplayString(_ctx.t("salesOrder.salesProfilling")), 1))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: 
              _ctx.getDraftOrderRouteUrl(_ctx.$route) +
              _ctx.$route.params.id +
              '/sales-profilling'
            ,
                  replace: "",
                  class: "nav-link text-active-primary ms-0 me-10 py-5",
                  "active-class": "active"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("salesOrder.salesProfilling")), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view, {
      onGetFormData: _ctx.getFormInfo,
      onGetIsChangeData: _ctx.getIsChangeData,
      loading: _ctx.loading,
      userAccount: _ctx.userAccount,
      draftOverview: _ctx.DraftOrderOverview
    }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { ref: "childMainRef" }, null, 512))
      ]),
      _: 1
    }, 8, ["onGetFormData", "onGetIsChangeData", "loading", "userAccount", "draftOverview"])
  ], 64))
}