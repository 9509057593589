
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import md5 from "js-md5";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { ApiDraftOrders } from "@/core/api";
import {
  getSalesOrderFulfillmentStatus,
  getSalesOrderPaymentStatus,
  getSalesOrderStatus,
} from "@/core/directive/function/salesOrder";
import { NumberOrString } from "@/core/directive/type/common";
import { formatDateTime } from "@/core/directive/function/common";
import { draftOrderOverview } from "@/core/directive/interface/salesOrder";
import {
  defaultAddressData,
  defaultProfileData,
} from "@/core/directive/interface/common";
import { getDraftOrderRouteUrl } from "@/core/directive/function/doubleSaleOrder";
import WindowResize from "@/components/layout/WindowResize.vue";

export default defineComponent({
  name: "sales-order-draft-order-detail",
  components: { WindowResize },
  props: {
    type: {
      type: Number,
      default: 0, // 0: sales order draft orders 1: double sale order draft orders
    },
  },
  setup(props) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();

    const childMainRef = ref();
    const loading = ref(true);
    const isChangeData = ref(false);
    const DraftOrderOverview = ref(Object.assign({}, draftOrderOverview));

    const options = ref({
      distribution_model: new Map([]),
      payment_status: new Map([
        [1, t("salesOrder.paymentStatusPending")],
        [2, t("salesOrder.paymentStatusPaid")],
      ]),
    });

    // 获取子页面数据是否修改
    const getIsChangeData = (value: boolean) => {
      isChangeData.value = value;
    };

    const isOverview = computed(() => {
      let flag = false;
      switch (route.name) {
        case "sales-order-draft-order-overview":
        case "double-sale-order-draft-order-overview":
          flag = true;
          break;
      }
      return flag;
    });

    const isDoubleSale = computed(() => {
      return props.type === 1;
    });

    const goSalesProfilling = () => {
      Swal.fire({
        text: t("salesOrderOverview.goSalesProfilingTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (DraftOrderOverview.value.relation_item_draft.length === 0) {
            Swal.fire({
              text: t("salesOrderOverview.addGoodsTip"),
              icon: "warning",
              showCancelButton: false,
              buttonsStyling: false,
              confirmButtonText: t("common.yes"),
              cancelButtonText: t("common.no"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
              },
            }).then(async (result) => {
              // ;
            });
          } else {
            childMainRef.value?.savetoSalesProfilling();
          }
        }
      });
    };

    const getShowInfo = async () => {
      const { data } = isDoubleSale.value
        ? await ApiDraftOrders.getDoubleSaleDraftOrderShow({
            order_id: route.params.id,
          })
        : await ApiDraftOrders.getShowInfo({
            order_id: route.params.id,
          });
      if (data.code == 0) {
        const info = data.data;
        if (!isDoubleSale.value) {
          const billing_address = Object.assign({}, defaultAddressData);
          const shipping_address = Object.assign({}, defaultAddressData);
          if (info.billing_address) {
            // info.billing_address = Object.assign(billing_address, {
            //   firstname: info.billing_address.firstname,
            //   lastname: info.billing_address.lastname,
            //   company: info.billing_address.company,
            //   telephone: info.billing_address.telephone,
            //   country_id: info.billing_address.country_iso_2,
            //   region: info.billing_address.region,
            //   city: info.billing_address.city,
            //   street: info.billing_address.street,
            //   postcode: info.billing_address.zip_code,
            // });
          } else {
            info.billing_address = billing_address;
          }
          if (info.shipping_address) {
            // info.shipping_address = Object.assign(shipping_address, {
            //   firstname: info.shipping_address.firstname,
            //   lastname: info.shipping_address.lastname,
            //   company: info.shipping_address.company,
            //   telephone: info.shipping_address.telephone,
            //   country_id: info.shipping_address.country_iso_2,
            //   region: info.shipping_address.region,
            //   city: info.shipping_address.city,
            //   street: info.shipping_address.street,
            //   postcode: info.shipping_address.zip_code,
            // });
            if (
              md5(JSON.stringify(info.shipping_address)) ===
              md5(JSON.stringify(info.billing_address))
            ) {
              info.isSameBillingAddress = true;
            } else {
              info.isSameBillingAddress = false;
            }
          } else {
            info.isSameBillingAddress = true;
            info.shipping_address = shipping_address;
          }
          if (!info.profile) {
            info.profile = Object.assign({}, defaultProfileData);
          }
        } else {
          //
        }
        // info.relation_item_draft.forEach((item) => {
        //   if (info.config_id == "2") {
        //     item.base_gross_price = getOrderGrossPrice(
        //       item.base_price,
        //       item.tax_rate
        //     );
        //   }
        // });
        DraftOrderOverview.value = info;
      } else {
        showServerErrorMsg(data);
      }
    };

    const isInitPage = computed(() => {
      let flag = false;
      switch (route.name) {
        case "sales-order-draft-order-sales-profilling":
        case "double-sale-order-draft-order-sales-profilling":
          flag = true;
          break;
      }
      return flag;
    });

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const orderStatus = computed(() => {
      return (status: string) => {
        return getSalesOrderStatus(status, t);
      };
    });

    const paymentStatus = computed(() => {
      return (status: string) => {
        return getSalesOrderPaymentStatus(status, t);
      };
    });

    const fulfillmentStatus = computed(() => {
      return (status: NumberOrString) => {
        return getSalesOrderFulfillmentStatus(status, t);
      };
    });

    const userAccount = computed(() => {
      return DraftOrderOverview.value.user_account_name
        .split(" ")
        .join("")
        .split(",")
        .join("");
      // return DraftOrderOverview.value.user_account_name
      //   .replace(" ", "")
      //   .replace(",", "");
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      //   getFormInfo();
      if (isInitPage.value) {
        getFormInfo();
      }
    });

    return {
      t,
      formatDateTime,
      getDraftOrderRouteUrl,
      childMainRef,
      loading,
      isChangeData,
      DraftOrderOverview,
      options,
      orderStatus,
      paymentStatus,
      fulfillmentStatus,
      userAccount,
      getIsChangeData,
      isOverview,
      isDoubleSale,
      goSalesProfilling,
      getFormInfo,
    };
  },
});
